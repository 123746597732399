<template>

  <v-hover v-slot="{ hover }">
  <v-card 
    :loading="loading"
    :class="{ 'on-hover': hover&&isOwn }"
    justify-center
    class=""
    max-width="500"
    @click="viewImage"
  >
    <!-- <v-overlay
      :absolute="true"
      :value="hover && isOwn"
  
    >
      <v-btn
        color="#00a7cf"
        @click="changeThumbnail"
      >
        サムネイルの変更
      </v-btn>
    </v-overlay> -->
  
    <v-img
      :src="img"
      :lazy-src="altImg"
      :class="{ 'on-hover-img': hover }"
      max-width="500"
      max-height="282"
      :aspect-ratop="16/9"  
      style="object-fit: cover;"

    > 
      
    <template v-slot:placeholder>
        <v-row v-if="loading"
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

      <v-dialog v-model="dialog" max-width="600px" style="z-index:2002;">
      <DialogThumbnailChange
        :thumbnail="thumbnail"
        :id="naviId"
        type="navi"
        v-on:closeEmit=" dialog = false;"
        v-on:saveEmit="save"
      />
    </v-dialog>

    <v-dialog v-model="dialogImage" max-width="850px" width="90%"  loading="false" persistent style="z-index:2001;">
      <DialogImageView
        :url="thumbnail" 
        loading=false
        :name="name"
        type="navi"
        v-on:closeEmit="close"
        v-on:thumbnailChangeEmit="changeThumbnail"
      />
    </v-dialog>
  </v-card>


  </v-hover>
  
</template> 

<script>
const DialogThumbnailChange = () => import('@/components/DialogThumbnailChange.vue');
const DialogImageView = () => import('@/components/DialogImageView.vue');

export default {
  components: { DialogThumbnailChange ,DialogImageView},
  name: 'NaviThumbnail',
  props:['thumbnail','loading','naviId','change','isOwn'],
  data () {
    return {
      img:this.thumbnail,
      dialog:false,
      dialogImage:false,
      name:"サムネイルの詳細",
      altImg:require("@/assets/navi.jpg")
    }
  },

  watch: {
    loading : function(){
      this.img = this.thumbnail
    },

    change : function(){
      console.log(this.thumbnail)
      setTimeout(function(){
        this.img = this.thumbnail
        console.log("now")
      },1000)
    },
   
  },

  methods : {
    changeThumbnail(){
      console.log("kk")
      this.dialog = true;
    },

    viewImage(){
      this.dialogImage = true;
    },
    save(img){
      this.img = img;
      this.$emit('saveEmit',img)
      this.dialog = false;
    },
    close(){
      this.dialog = false;
      this.dialogImage = false;
    }
  }

}
</script>

<style scoped>


.on-hover-img {
  transition: opacity .1s ease-in-out;
  opacity: 0.75;
 }

 .on-hover {
  background: black;
 }

</style>

